
import React from "react"
import Layout from "../components/layout"
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="Dunedin and Central Otago - New Zealand | Kiwi & Cacahuate" description="After being home for christmas Ivette came from Mexico, to explore New Zealand before we left to Melbourne." url="https://kiwicacahuate.com/otago/" image="https://kiwicacahuate.com/otago/otago.jpg" imageWidth="3000" imageHeight="2000" />

    <div className="">
      <div className="story">
        <div className="photoset-section">
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="20">
                <source srcSet="/dunedin-and-central-otago/thumbnail/20.webp, /dunedin-and-central-otago/thumbnail/20-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/20.jpg, /dunedin-and-central-otago/thumbnail/20-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/20.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-story">
            <h2 className="dairy-title">Dunedin</h2>
            <div className="photoset-intro left-align">
              <p>This was my first visit to Jeremy's hometown. It's a pretty small city, but with lots of charm. The best part was the beach. We also went on a tour of the Cadbury factory (YUM!).</p>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item six">
              <picture data-name="01">
                <source srcSet="/dunedin-and-central-otago/thumbnail/01.webp, /dunedin-and-central-otago/thumbnail/01-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/01.jpg, /dunedin-and-central-otago/thumbnail/01-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/01.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item six">
              <picture data-name="02">
                <source srcSet="/dunedin-and-central-otago/thumbnail/02.webp, /dunedin-and-central-otago/thumbnail/02-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/02.jpg, /dunedin-and-central-otago/thumbnail/02-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/02.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="04">
                <source srcSet="/dunedin-and-central-otago/thumbnail/04.webp, /dunedin-and-central-otago/thumbnail/04-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/04.jpg, /dunedin-and-central-otago/thumbnail/04-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/04.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item six">
              <picture data-name="05">
                <source srcSet="/dunedin-and-central-otago/thumbnail/05.webp, /dunedin-and-central-otago/thumbnail/05-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/05.jpg, /dunedin-and-central-otago/thumbnail/05-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/05.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item four">
              <picture data-name="06">
                <source srcSet="/dunedin-and-central-otago/thumbnail/06.webp, /dunedin-and-central-otago/thumbnail/06-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/06.jpg, /dunedin-and-central-otago/thumbnail/06-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/06.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item four">
              <picture data-name="07">
                <source srcSet="/dunedin-and-central-otago/thumbnail/07.webp, /dunedin-and-central-otago/thumbnail/07-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/07.jpg, /dunedin-and-central-otago/thumbnail/07-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/07.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item six loaded">
              <video muted="true" loop="true" preload="true" autoPlay poster="dunedin-and-central-otago/original/signal-hill.png">
                <source src="/dunedin-and-central-otago/original/signal-hill.mp4" type="video/mp4" />
                "Your browser does not support video streaming via HTML5."
               </video>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="08">
                <source srcSet="/dunedin-and-central-otago/thumbnail/08.webp, /dunedin-and-central-otago/thumbnail/08-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/08.jpg, /dunedin-and-central-otago/thumbnail/08-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/08.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="09">
                <source srcSet="/dunedin-and-central-otago/thumbnail/09.webp, /dunedin-and-central-otago/thumbnail/09-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/09.jpg, /dunedin-and-central-otago/thumbnail/09-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/09.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-story">
            <h2 className="dairy-title">Nature wonders</h2>
            <div className="photoset-intro left-align">
              <p>Little did I know that Dunedin is right next to a protected marine area, where seals and penguins live. The views are amazing, and the seals were so cute!</p>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item six">
              <picture data-name="10">
                <source srcSet="/dunedin-and-central-otago/thumbnail/10.webp, /dunedin-and-central-otago/thumbnail/10-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/10.jpg, /dunedin-and-central-otago/thumbnail/10-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/10.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item four">
              <picture data-name="11">
                <source srcSet="/dunedin-and-central-otago/thumbnail/11.webp, /dunedin-and-central-otago/thumbnail/11-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/11.jpg, /dunedin-and-central-otago/thumbnail/11-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/11.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="12">
                <source srcSet="/dunedin-and-central-otago/thumbnail/12.webp, /dunedin-and-central-otago/thumbnail/12-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/12.jpg, /dunedin-and-central-otago/thumbnail/12-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/12.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="14">
                <source srcSet="/dunedin-and-central-otago/thumbnail/14.webp, /dunedin-and-central-otago/thumbnail/14-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/14.jpg, /dunedin-and-central-otago/thumbnail/14-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/14.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="15">
                <source srcSet="/dunedin-and-central-otago/thumbnail/15.webp, /dunedin-and-central-otago/thumbnail/15-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/15.jpg, /dunedin-and-central-otago/thumbnail/15-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/15.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-story">
            <h2 className="dairy-title">Baldwin Street and Signal Hill</h2>
            <div className="photoset-intro left-align">
              <p>We couldn't miss a visit to the most touristic spot in Dunedin: Baldwin Street (the steepest street in the world). I feel sorry for the poor kids that live at the top of it, I could barely make it.</p>
            </div>
          </div>


          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="21">
                <source srcSet="/dunedin-and-central-otago/thumbnail/21.webp, /dunedin-and-central-otago/thumbnail/21-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/21.jpg, /dunedin-and-central-otago/thumbnail/21-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/21.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="17">
                <source srcSet="/dunedin-and-central-otago/thumbnail/17.webp, /dunedin-and-central-otago/thumbnail/17-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/17.jpg, /dunedin-and-central-otago/thumbnail/17-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/17.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="19">
                <source srcSet="/dunedin-and-central-otago/thumbnail/19.webp, /dunedin-and-central-otago/thumbnail/19-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/19.jpg, /dunedin-and-central-otago/thumbnail/19-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/19.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>


          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="22">
                <source srcSet="/dunedin-and-central-otago/thumbnail/22.webp, /dunedin-and-central-otago/thumbnail/22-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/22.jpg, /dunedin-and-central-otago/thumbnail/22-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/22.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item six">
              <picture data-name="24">
                <source srcSet="/dunedin-and-central-otago/thumbnail/24.webp, /dunedin-and-central-otago/thumbnail/24-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/24.jpg, /dunedin-and-central-otago/thumbnail/24-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/24.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item four">
              <picture data-name="25">
                <source srcSet="/dunedin-and-central-otago/thumbnail/25.webp, /dunedin-and-central-otago/thumbnail/25-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/25.jpg, /dunedin-and-central-otago/thumbnail/25-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/25.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="26">
                <source srcSet="/dunedin-and-central-otago/thumbnail/26.webp, /dunedin-and-central-otago/thumbnail/26-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/26.jpg, /dunedin-and-central-otago/thumbnail/26-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/26.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="27">
                <source srcSet="/dunedin-and-central-otago/thumbnail/27.webp, /dunedin-and-central-otago/thumbnail/27-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/27.jpg, /dunedin-and-central-otago/thumbnail/27-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/27.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-story">
            <h2 className="dairy-title">Hawera and Central Otago</h2>
            <div className="photoset-intro left-align">
              <p>People in New Zealand are weird, they think 0 degree water is hot. However the days were really sunny and warm. Hawera is one of those places to forget about the chaos of the city, and enjoy the blue water, beautiful mountains and incredible sunsets.</p>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="28">
                <source srcSet="/dunedin-and-central-otago/thumbnail/28.webp, /dunedin-and-central-otago/thumbnail/28-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/28.jpg, /dunedin-and-central-otago/thumbnail/28-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/28.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="29">
                <source srcSet="/dunedin-and-central-otago/thumbnail/29.webp, /dunedin-and-central-otago/thumbnail/29-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/29.jpg, /dunedin-and-central-otago/thumbnail/29-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/29.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="30">
                <source srcSet="/dunedin-and-central-otago/thumbnail/30.webp, /dunedin-and-central-otago/thumbnail/30-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/30.jpg, /dunedin-and-central-otago/thumbnail/30-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/30.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="31">
                <source srcSet="/dunedin-and-central-otago/thumbnail/31.webp, /dunedin-and-central-otago/thumbnail/31-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/31.jpg, /dunedin-and-central-otago/thumbnail/31-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/31.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="34">
                <source srcSet="/dunedin-and-central-otago/thumbnail/34.webp, /dunedin-and-central-otago/thumbnail/34-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/34.jpg, /dunedin-and-central-otago/thumbnail/34-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/34.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="32">
                <source srcSet="/dunedin-and-central-otago/thumbnail/32.webp, /dunedin-and-central-otago/thumbnail/32-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/32.jpg, /dunedin-and-central-otago/thumbnail/32-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/32.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="33">
                <source srcSet="/dunedin-and-central-otago/thumbnail/33.webp, /dunedin-and-central-otago/thumbnail/33-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/33.jpg, /dunedin-and-central-otago/thumbnail/33-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/33.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>



          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="35">
                <source srcSet="/dunedin-and-central-otago/thumbnail/35.webp, /dunedin-and-central-otago/thumbnail/35-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/35.jpg, /dunedin-and-central-otago/thumbnail/35-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/35.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="36">
                <source srcSet="/dunedin-and-central-otago/thumbnail/36.webp, /dunedin-and-central-otago/thumbnail/36-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/36.jpg, /dunedin-and-central-otago/thumbnail/36-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/36.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five loaded">
              <video muted="true" loop="true" preload="true" autoPlay poster="dunedin-and-central-otago/original/lakeside.png">
                <source src="/dunedin-and-central-otago/original/lakeside.mp4" type="video/mp4" />
                "Your browser does not support video streaming via HTML5."
              </video>
            </div>
          </div>


          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="62">
                <source srcSet="/dunedin-and-central-otago/thumbnail/62.webp, /dunedin-and-central-otago/thumbnail/62-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/62.jpg, /dunedin-and-central-otago/thumbnail/62-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/62.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="63">
                <source srcSet="/dunedin-and-central-otago/thumbnail/63.webp, /dunedin-and-central-otago/thumbnail/63-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/63.jpg, /dunedin-and-central-otago/thumbnail/63-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/63.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-story">
            <h2 className="dairy-title">Queenstown</h2>
            <div className="photoset-intro left-align">
              <p>We went for a day trip to Queenstown, known to be the most beautiful and touristic place in New Zealand. I get why, but since it’s too much to describe, just watch the video!</p>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten loaded responsive-embed-youtube">
              <iframe src="https://www.youtube.com/embed/3vTA0idhlPQ?rel=0&showinfo=0&VQ=HD1080" frameborder="0" allowfullscreen title="Youtube"></iframe>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item three">
              <picture data-name="39">
                <source srcSet="/dunedin-and-central-otago/thumbnail/39.webp, /dunedin-and-central-otago/thumbnail/39-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/39.jpg, /dunedin-and-central-otago/thumbnail/39-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/39.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item seven">
              <picture data-name="40">
                <source srcSet="/dunedin-and-central-otago/thumbnail/40.webp, /dunedin-and-central-otago/thumbnail/40-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/40.jpg, /dunedin-and-central-otago/thumbnail/40-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/40.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item seven">
              <picture data-name="41">
                <source srcSet="/dunedin-and-central-otago/thumbnail/41.webp, /dunedin-and-central-otago/thumbnail/41-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/41.jpg, /dunedin-and-central-otago/thumbnail/41-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/41.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item three">
              <picture data-name="42">
                <source srcSet="/dunedin-and-central-otago/thumbnail/42.webp, /dunedin-and-central-otago/thumbnail/42-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/42.jpg, /dunedin-and-central-otago/thumbnail/42-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/42.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="43">
                <source srcSet="/dunedin-and-central-otago/thumbnail/43.webp, /dunedin-and-central-otago/thumbnail/43-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/43.jpg, /dunedin-and-central-otago/thumbnail/43-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/43.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="44">
                <source srcSet="/dunedin-and-central-otago/thumbnail/44.webp, /dunedin-and-central-otago/thumbnail/44-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/44.jpg, /dunedin-and-central-otago/thumbnail/44-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/44.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="47">
                <source srcSet="/dunedin-and-central-otago/thumbnail/47.webp, /dunedin-and-central-otago/thumbnail/47-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/47.jpg, /dunedin-and-central-otago/thumbnail/47-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/47.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item six">
              <picture data-name="45">
                <source srcSet="/dunedin-and-central-otago/thumbnail/45.webp, /dunedin-and-central-otago/thumbnail/45-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/45.jpg, /dunedin-and-central-otago/thumbnail/45-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/45.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item four">
              <picture data-name="46">
                <source srcSet="/dunedin-and-central-otago/thumbnail/46.webp, /dunedin-and-central-otago/thumbnail/46-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/46.jpg, /dunedin-and-central-otago/thumbnail/46-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/46.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>


          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="48">
                <source srcSet="/dunedin-and-central-otago/thumbnail/48.webp, /dunedin-and-central-otago/thumbnail/48-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/48.jpg, /dunedin-and-central-otago/thumbnail/48-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/48.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item three">
              <picture data-name="49">
                <source srcSet="/dunedin-and-central-otago/thumbnail/49.webp, /dunedin-and-central-otago/thumbnail/49-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/49.jpg, /dunedin-and-central-otago/thumbnail/49-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/49.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item seven">
              <picture data-name="50">
                <source srcSet="/dunedin-and-central-otago/thumbnail/50.webp, /dunedin-and-central-otago/thumbnail/50-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/50.jpg, /dunedin-and-central-otago/thumbnail/50-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/50.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="59">
                <source srcSet="/dunedin-and-central-otago/thumbnail/59.webp, /dunedin-and-central-otago/thumbnail/59-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/59.jpg, /dunedin-and-central-otago/thumbnail/59-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/59.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="51">
                <source srcSet="/dunedin-and-central-otago/thumbnail/51.webp, /dunedin-and-central-otago/thumbnail/51-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/51.jpg, /dunedin-and-central-otago/thumbnail/51-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/51.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="52">
                <source srcSet="/dunedin-and-central-otago/thumbnail/52.webp, /dunedin-and-central-otago/thumbnail/52-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/52.jpg, /dunedin-and-central-otago/thumbnail/52-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/52.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>


          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="54">
                <source srcSet="/dunedin-and-central-otago/thumbnail/54.webp, /dunedin-and-central-otago/thumbnail/54-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/54.jpg, /dunedin-and-central-otago/thumbnail/54-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/54.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>


          <div className="photoset-row">
            <div className="photoset-item six">
              <picture data-name="53">
                <source srcSet="/dunedin-and-central-otago/thumbnail/53.webp, /dunedin-and-central-otago/thumbnail/53-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/53.jpg, /dunedin-and-central-otago/thumbnail/53-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/53.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item four">
              <picture data-name="55">
                <source srcSet="/dunedin-and-central-otago/thumbnail/55.webp, /dunedin-and-central-otago/thumbnail/55-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/55.jpg, /dunedin-and-central-otago/thumbnail/55-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/55.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item four">
              <picture data-name="58">
                <source srcSet="/dunedin-and-central-otago/thumbnail/58.webp, /dunedin-and-central-otago/thumbnail/58-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/58.jpg, /dunedin-and-central-otago/thumbnail/58-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/58.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item six loaded">
              <video muted="true" loop="true" preload="true" autoPlay poster="dunedin-and-central-otago/original/central-drive.png">
                <source src="/dunedin-and-central-otago/original/central-drive.mp4" type="video/mp4" />
                "Your browser does not support video streaming via HTML5."
              </video>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="60">
                <source srcSet="/dunedin-and-central-otago/thumbnail/60.webp, /dunedin-and-central-otago/thumbnail/60-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/60.jpg, /dunedin-and-central-otago/thumbnail/60-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/60.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="61">
                <source srcSet="/dunedin-and-central-otago/thumbnail/61.webp, /dunedin-and-central-otago/thumbnail/61-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/61.jpg, /dunedin-and-central-otago/thumbnail/61-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/61.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-story">
            <h2 className="dairy-title">Wellington</h2>
            <div className="photoset-intro left-align">
              <p>Wellington (the capital) is most known to be the windy city, which we can attest to be true. Jeremy lived here before and I think I most certainly would. It has a little bit of everything, but mostly a very relaxed vibe.</p>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="64">
                <source srcSet="/dunedin-and-central-otago/thumbnail/64.webp, /dunedin-and-central-otago/thumbnail/64-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/64.jpg, /dunedin-and-central-otago/thumbnail/64-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/64.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item seven">
              <picture data-name="66">
                <source srcSet="/dunedin-and-central-otago/thumbnail/66.webp, /dunedin-and-central-otago/thumbnail/66-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/66.jpg, /dunedin-and-central-otago/thumbnail/66-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/66.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item three">
              <picture data-name="65">
                <source srcSet="/dunedin-and-central-otago/thumbnail/65.webp, /dunedin-and-central-otago/thumbnail/65-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/65.jpg, /dunedin-and-central-otago/thumbnail/65-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/65.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="67">
                <source srcSet="/dunedin-and-central-otago/thumbnail/67.webp, /dunedin-and-central-otago/thumbnail/67-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/67.jpg, /dunedin-and-central-otago/thumbnail/67-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/67.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="68">
                <source srcSet="/dunedin-and-central-otago/thumbnail/68.webp, /dunedin-and-central-otago/thumbnail/68-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/68.jpg, /dunedin-and-central-otago/thumbnail/68-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/68.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="69">
                <source srcSet="/dunedin-and-central-otago/thumbnail/69.webp, /dunedin-and-central-otago/thumbnail/69-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/69.jpg, /dunedin-and-central-otago/thumbnail/69-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/69.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="71">
                <source srcSet="/dunedin-and-central-otago/thumbnail/71.webp, /dunedin-and-central-otago/thumbnail/71-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/71.jpg, /dunedin-and-central-otago/thumbnail/71-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/71.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="72">
                <source srcSet="/dunedin-and-central-otago/thumbnail/72.webp, /dunedin-and-central-otago/thumbnail/72-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/72.jpg, /dunedin-and-central-otago/thumbnail/72-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/72.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item three">
              <picture data-name="75">
                <source srcSet="/dunedin-and-central-otago/thumbnail/75.webp, /dunedin-and-central-otago/thumbnail/75-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/75.jpg, /dunedin-and-central-otago/thumbnail/75-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/75.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item seven">
              <picture data-name="76">
                <source srcSet="/dunedin-and-central-otago/thumbnail/76.webp, /dunedin-and-central-otago/thumbnail/76-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/76.jpg, /dunedin-and-central-otago/thumbnail/76-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/76.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="77">
                <source srcSet="/dunedin-and-central-otago/thumbnail/77.webp, /dunedin-and-central-otago/thumbnail/77-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/77.jpg, /dunedin-and-central-otago/thumbnail/77-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/77.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="73">
                <source srcSet="/dunedin-and-central-otago/thumbnail/73.webp, /dunedin-and-central-otago/thumbnail/73-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/73.jpg, /dunedin-and-central-otago/thumbnail/73-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/73.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-story">
            <div className="photoset-intro left-align">
              <p>Wellington (the capital) is most known to be the windy city, which we can attest to be true. Jeremy lived here before and I think I most certainly would. It has a little bit of everything, but mostly a very relaxed vibe.</p>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="79">
                <source srcSet="/dunedin-and-central-otago/thumbnail/79.webp, /dunedin-and-central-otago/thumbnail/79-2x.webp 2x" />
                <source srcSet="/dunedin-and-central-otago/thumbnail/79.jpg, /dunedin-and-central-otago/thumbnail/79-2x.jpg 2x" />
                <img src="/dunedin-and-central-otago/thumbnail/79.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

